<template>
  <b-form @submit.prevent="updateProvider">
    <b-form-group label="Nom" label-for="name-input">
      <b-form-input
          id="name-input"
          v-model="$v.p.projectId.$model"
          type="text"
          required
          placeholder="Renseignez un identifiant de projet"
          :state="$v.p.projectId.$dirty ? !$v.p.projectId.$invalid : null"
      />
      <b-form-invalid-feedback :state="!$v.p.projectId.$invalid">
        <span v-if="!$v.p.projectId.required">Vous devez renseigner ce champ</span>
      </b-form-invalid-feedback>
    </b-form-group>
    <b-button type="submit" variant="primary" :disabled="$v.$invalid">
      Envoyer
    </b-button>
  </b-form>
</template>

<script>
import {required} from "vuelidate/lib/validators"

export default {
  props: {
    provider: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      p: Object.assign({}, this.provider) // copy to protect original value
    }
  },
  validations: {
    p: {
      projectId: {required},
    }
  },
  methods: {
    async updateProvider() {
      try {
        await this.$store.dispatch('providers/updateProvider', {type: 'firebase', provider: this.p})
      } catch (e) {
        await this.$swal.fire({
          icon: "error",
          title:
              "Une erreur est survenue lors de la mise à jour du fournisseur d'authentification Firebase",
          text: e.message,
        });
        return
      }
      await this.$swal.fire({
        icon: "success",
        title: "Fournisseur d'authentification Firebase modifié",
      });
    }
  }
}
</script>
